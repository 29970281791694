<template>
  <b-overlay :show="isLoading" rounded="lg">
  <b-card title="All Item Inventory">
    <b-modal v-model="editModal" title="Edit Inventory" size="lg">      
      <b-form>
        <b-card-actions-container class="bv-edit-inventory">
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label id="edit-inventory-name" for="edit-inventory-name-input">Model</label>
                <b-form-input
                  id="edit-hardware-name-input"
                  v-model="dataEdit.itemModel"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label id="edit-inventory-name" for="edit-inventory-name-input">DO</label>
                <b-form-input
                  id="edit-hardware-name-input"
                  v-model="dataEdit.sapDo"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label id="edit-inventory-name" for="edit-inventory-name-input">Dealer Code</label>
                <b-form-input
                  id="edit-hardware-name-input"
                  v-model="dataEdit.dealerCode"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label id="edit-inventory-name" for="edit-inventory-name-input">Barcode</label>
                <b-form-input
                  id="edit-hardware-name-input"
                  v-model="dataEdit.barcode"
                  :disabled="dataEdit.skip"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label id="edit-inventory-name" for="edit-inventory-name-input">Serial</label>
                <b-form-input
                  id="edit-hardware-name-input"
                  v-model="dataEdit.serial"
                  :disabled="dataEdit.skip"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label id="edit-inventory-name" for="edit-inventory-name-input">PCode</label>
                <b-form-input
                  id="edit-hardware-name-input"
                  v-model="dataEdit.pcode"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Solid divider -->
              <hr class="solid">
            </b-col>               
          </b-row>
        </b-card-actions-container>
        <div class="w-100">
          <b-button @click="onSubmitEdit" class="float-right" variant="primary">Submit</b-button>  
        </div>
        <div class="w-100">
          <b-button @click="cancelEdit" class="float-right" variant="danger">Cancel</b-button>  
        </div>
      </b-form> 
      <template #modal-footer>
        <div class="w-100">    
        </div>
      </template>
    </b-modal>
    <b-modal v-model="modalDelete" title="Delete Inventory">
      <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12" >
            <div class="d-flex">
              Are you sure want to delete this {{ selectedItem.barcode? selectedItem.barcode : "Skip Scan"}} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDelete">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <b-col cols="12" class="mb-1 hide-on-mobile">
      <span><b>Filter by :</b></span>
      <div class="filter-responsive">
        <div class="input-group">
          <div class="input-group-prepend">
            <b-dropdown style="margin: 0 !important;" id="dropdown-1" :text="selectedFilter" class="m-md-2">
              <b-dropdown-item @click="dropDownMenuClick" field="barcode">Barcode</b-dropdown-item>
              <b-dropdown-item @click="dropDownMenuClick" field="sapDo">DO</b-dropdown-item>
              <!-- <b-dropdown-item @click="dropDownMenuClick" field="warehouse">Warehouse</b-dropdown-item> -->

            </b-dropdown>
          </div>
          <b-form-select
            v-model="type"
            v-if="selectedField==='serial'||selectedField==='barcode'"
            :options="typeOptions"
            size="md"
            class="w-5"
            />
            <b-form-select
            v-model="typeDo"
            v-if="selectedField==='sapDo'"
            :options="typeDoOptions"
            size="md"
            class="w-5"
            />
            
            
            <b-form-input v-if="selectedField!=='warehouse' && selectedField!=='modelandserial'" :disabled="typeDo==='Not Set Up'||type!=='Set Up'"  v-model="toFilter" placeholder="Filter.."/>
            <b-form-select
            v-model="warehouse"
            v-if="selectedField==='warehouse'"
            :options="warehouseOptions"
            size="md"
            class="w-25"
          >
        </b-form-select>
        </div>
      </div>
    </b-col>
    <b-col cols="12" class="mb-2 hide-on-mobile">
      <span><b>Or</b></span>
      <div class="filter-responsive">
        <div class="input-group">
          <b-col cols="6">
            <span>Model No <span style="color:red">(*)</span></span>
            <b-form-input
              :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
              v-model="toFilterModel"
              placeholder="Add Model"
            ></b-form-input>
          </b-col>
          <b-col cols="6">
            <span>Serial No. <span style="color:red">(*)</span></span>
            <b-form-input
              :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
              v-model="toFilterSerial"
              placeholder="Add Serial"
            ></b-form-input>
          </b-col>
        </div>
      </div>
      <br>
      <div class="filter-responsive">
  <div class="input-group">
    <b-col cols="6">
      <b-form-datepicker
        placeholder="Add Delivery date"
        v-model="delivDate"
        class="custom-datepicker" 
      />
    </b-col>
    <b-col>
      <b-button @click="doFilter" variant="primary">Filter</b-button>
      <b-button @click="clearFilter" variant="primary">Clear</b-button>
    </b-col>
  </div>
</div>
    </b-col>
    <b-row class="mb-2 hide-on-mobile">
    <b-col cols="12" class="p-1 m-1">
      <span><b>Sort By</b></span>
    </b-col>
    <b-row class="d-flex align-items-center" style="margin-left:50px;">
  <b-col cols="auto">
    <b-button 
      :variant="modelVariant" 
      v-model="filters.model" 
      :disabled="disableButton" 
      @click="sortClick('model')" 
      style="margin-right: 10px;">
      Model
    </b-button>
  </b-col>
  <b-col cols="auto">
    <b-button 
      :variant="serialVariant" 
      v-model="filters.serial" 
      :disabled="disableButton" 
      @click="sortClick('serial')" 
      style="margin-right: 10px;">
      Serial
    </b-button>
  </b-col>
  <b-col cols="auto">
    <b-button 
      :variant="barcodeVariant" 
      v-model="filters.barcode" 
      :disabled="disableButton" 
      @click="sortClick('barcode')" 
      style="margin-right: 10px;">
      Barcode
    </b-button>
  </b-col>
    <b-col cols="auto">
      <b-form-select
        :disabled="items.length === 0"
        v-model="typeSort"
        :options="typeSortOptions"
        size="md"
        @change="onSortChange"
        style="margin-right: 250px;"
      />
    </b-col>
    </b-row>
</b-row>
    <b-col cols="12" class="mb-2 hide-on-desktop">
      <div class="filter-responsive ">
        <div class="input-group">
          <div class="input-group-prepend">
            <b-dropdown style="margin: 0 !important;" id="dropdown-1" :text="selectedFilter" class="m-md-2">
              <b-dropdown-item @click="dropDownMenuClick" field="barcode">Barcode</b-dropdown-item>
              <b-dropdown-item @click="dropDownMenuClick" field="sapDo">DO</b-dropdown-item>
              <!-- <b-dropdown-item @click="dropDownMenuClick" field="warehouse">Warehouse</b-dropdown-item> -->
            </b-dropdown>
          </div>
          <b-form-select
            v-model="type"
            v-if="selectedField==='serial'||selectedField==='barcode'"
            :options="typeOptions"
            size="md"
            class="w-5"
          />
          <b-form-select
            v-model="typeDo"
            v-if="selectedField==='sapDo'"
            :options="typeDoOptions"
            size="md"
            class="w-5"
          />
          <b-form-input
              v-if="selectedField === 'modelandserial'"
              :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
              v-model="toFilterModel"
              placeholder="Add Model"
            ></b-form-input>
            <b-form-input
              v-if="selectedField === 'modelandserial'"
              :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
              v-model="toFilterSerial"
              placeholder="Add Serial"
            ></b-form-input>
          <b-form-input v-if="selectedField!=='warehouse' && selectedField!=='modelandserial'" :disabled="typeDo==='Not Set Up'||type!=='Set Up'"  v-model="toFilter" placeholder="Filter.."/>
          <b-form-select
            v-model="warehouse"
            v-if="selectedField==='warehouse'"
            :options="warehouseOptions"
            size="md"
            class="w-25"
          >
          </b-form-select>
        </div>
      </div>
    </b-col>

    <b-col cols="12" class="mb-2 hide-on-desktop">
      <div class="filter-responsive">
        <div class="input-group">
          <b-form-input
            :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
            v-model="toFilterModel"
            placeholder="Add Model"
          ></b-form-input>
          <b-form-input
            :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
            v-model="toFilterSerial"
            placeholder="Add Serial"
          ></b-form-input>
        </div>
      </div>
    </b-col>

    <b-col cols="12" class="mt-2 mb-2 hide-on-desktop">
        <b-button @click="doFilter" class="mr-2" variant="primary">Filter</b-button>
        <b-button @click="clearFilter" class="mr-2" variant="primary">Clear</b-button>
    </b-col>
    
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :fields="fields"
            :items="items"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(customer)="row">
               {{row.item.deliveryAddress2}}
               <!--{{ row.item.customer.type === 'Company' ? row.item.customer.companyName : ${row.item.customer.nameFirst} ${row.item.customer.nameLast} }} -->
            
            </template>

            <template #cell(dealer)="row">
              {{row.item.dealerCode}}
              <!--{{ getDealer(row.item.dealer).dealerName}}-->
            </template>
            <template #cell(dealerName)="row">
              {{row.item.dealerName}}
              <!--{{ getDealer(row.item.dealer).dealerName}}-->
            </template>

            <template #cell(sapDo)="row">
              {{row.item.sapDo===''? '-' : row.item.sapDo}}
            </template>

             <template #cell(barcode)="row">
              {{scanningStatus(row.item).barcode}}
            </template>

            <template #cell(serial)="row">
              {{scanningStatus(row.item).serial}}
            </template>

            <template #cell(status)="row">
              {{scanningStatus(row.item).status}}
            </template>

            <template #cell(warehouse)="row">
              {{getWarehouse(row.item.shippingPoint)}}
            </template>

            <template #cell(updatedAt)="row">
              {{row.item.updatedAt?dates(row.item.updatedAt):''}}
            </template>

            <template #cell(scanUser)="row">
              {{(row.item.scanUser!==undefined)?row.item.scanUser.name:""}}
            </template>

            <template #cell(actions)="row">
              <b-dropdown id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <div class="dropdown-show">
                  <b-dropdown-item style="text-align:center">
                    <b-button variant="info" @click="toDetail(row.item)">
                      <feather-icon icon="EyeIcon"/>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="permission.edit && row.item.scanStatus == 'Scan' " style="text-align:center">
                    <b-button variant="success" @click="edit(row.item, row.index, $event.target)">
                      <feather-icon icon="EditIcon"/>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="permission.edit" style="text-align:center">
                    <b-button variant="danger" @click="deleted(row.item)">
                      <feather-icon icon="Trash2Icon"/>
                    </b-button>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
  </b-overlay>
</template>

<script>
import axios from '@/axios';
import { mapActions, mapState, mapGetters } from 'vuex';
import { userAccess,dateFormatFull } from "@/utils/utils.js";
import { filterTags } from '@/@core/utils/filter';

export default {
  data() {
    return {
      inventory: [],
      fields: [
        { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
        { key: 'barcode', label: 'Barcode', filterable: true, sortable: true },
        { key: 'serial', label: 'Serial', filterable: true, sortable: true },
        { key: 'status', label: 'Status', filterable: true, sortable: true },
        { key: 'sapDo', label: 'DO', filterable: true, sortable: true },
        { key: 'dealer', label: 'Dealer Code', filterable: true, sortable: true },
        { key: 'dealerName', label: 'Dealer Name', filterable: true, sortable: true },
        { key: 'warehouse', label: 'Warehouse' },
        { key: 'warrantyStatus', label: 'Warranty Status', filterable: true, sortable: true },
        { key: 'scanUser',label:"Scan User"},
        { key: 'updatedBy', label:'Updated By'},
        { key: 'updatedAt', label:'Update At'},
        { key: 'actions', label: 'Action' },
      ],
      filters: {
        model: false,
        serial: false,
        barcode: false,
      },
      disableButton: true,
      search: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      isLoading: false,
      model: undefined,
      barcode: undefined,
      serial: undefined,
      sapDo: undefined,
      editModal: false,
      modalDelete: false,
      selectedItem: {},
      dataEdit: {},
      selectedFilter: 'Barcode',
      toFilterModel: '',
      toFilterSerial: '',
      selectedField: 'barcode',
      searchByModel: '',
      searchBySerial: '',
      tableLoading: true,
      toFilter:'',
      warehouse:'', 
      warehouseOptions: [
        { value: '1001', text: 'Daikin Warehouse' },
        { value: '1003', text: 'Sumitomo Warehouse' },
      ],
      type:'Set Up', 
      typeOptions: ['Set Up','Not Set Up','Skip Scan'],
      typeDo:'Set Up', 
      typeDoOptions: ['Set Up','Not Set Up'],
      typeSortOptions: ['Ascending','Descending',],
      typeSort: "Ascending",
      delivDate: ""
    }
  },
  mounted() {
    // this.getAllInventories({ 
    //   page: this.currentPage, 
    //   entry: this.perPage,
    //   serial: this.serial,
    //   barcode: this.barcode,
    //   sapDo: this.sapDo,
    //   model: this.model,
    //   warehouse: this.warehouse
    // }).then((data) => this.processMetadata(data.metadata));

    console.log(this.permission);
  },
  created() {
    document.title = 'View Inventory | RSP'
  },
  methods: {
    ...mapActions({
      getAllInventories: 'inventory/getAllInventories'
    }),
    toggleFilter() {
      
    },
    dates(date) {
      return dateFormatFull(date);
    },
    scanningStatus(row){
      let barcode = row.barcode;
      let serial = row.serial;
      let status
      if(row.skip===true){
        barcode = 'Skip Scan';
        serial = 'Skip Scan'
      }
      
      if(row.scanStatus==="Not Scan" && row.unscanUser !== undefined){
        barcode = '-';
        status = 'Unscanned'
      }
      if(row.scanStatus==="Scan"){
        status = 'Scan'
      }
      if(row.scanStatus==="Not Scan" && row.unscanUser === undefined){
        barcode = '-';
        serial = '-';
        status = 'Not Scan'
      }

      

      return {
        barcode,
        serial,
        status
      }
    },
    getDealer(row){
      let named = row 
      if(row===null || row===undefined || row===""){
        named = {dealerName:'Not Found'}
      }
      return named
    },
    getWarehouse(row){
      let named = 'Not Found'
      if(row==='1001'){
        named = 'Daikin'
      }
      if(row==='1003'){
        named ='Sumitomo'
      }
      return named
    },
    onPageChange(page) {
      let filter = this.getFilter();

      filter.sortQuery = {}
      let sortWith = 1
      if (this.typeSort == "Ascending"){
        sortWith = 1
      } else {
        sortWith = -1
      }

      if(this.filters.model){
        filter.sortQuery.itemModel = sortWith
      }

      if(this.filters.serial){
        filter.sortQuery.serial = sortWith
      }

      if(this.filters.barcode){
        filter.sortQuery.barcode = sortWith
      }

      if(this.delivDate != ""){
        filter.deliveryDate = this.delivDate
      }

      this.getAllInventories({ 
        page: page, 
        entry: this.perPage,
        ...filter,
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    doFilter() {
      this.disableButton = false
      this.isLoading = true
      let filter = this.getFilter();
      filter.sortQuery = {}
      let sortWith = 1
      if (this.typeSort == "Ascending"){
        sortWith = 1
      } else {
        sortWith = -1
      }

      if(this.filters.model){
        filter.sortQuery.itemModel = sortWith
      }

      if(this.filters.serial){
        filter.sortQuery.serial = sortWith
      }

      if(this.filters.barcode){
        filter.sortQuery.barcode = sortWith
      }

      if(this.delivDate != ""){
        filter.deliveryDate = this.delivDate
      }
      console.log(filter)
      this.getAllInventories({ 
        entry: this.perPage,
        ...filter,
      }).then((data) => {
        this.processMetadata(data.metadata)
        this.isLoading = false
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      })
    },
    toDetail(item) {
      this.$router.push(`/inventory/detail/${item._id}`);
    },
    dropDownMenuClick(event) {
      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute('field');
      
      //every change selected filter, will reset type to : 
      this.typeDo='Set Up'
      this.type='Set Up'
    },
    getFilter() {
      if (this.selectedFilter === null) {
        return {};
      }
      let filter = {};

      let tempValue = this.toFilter
      let tempFilter = this.selectedField
      // if(this.selectedFilter === 'Warehouse'){
      //   tempValue  = this.warehouse;
      // }

      if(this.type ==='Not Set Up' || this.typeDo ==='Not Set Up'){
        tempValue = "Not Scan"
      }

      if(this.selectedFilter === 'Serial' && this.type ==='Skip Scan'){
        tempFilter = 'skip'
        tempValue  = true;
      }

      if(this.selectedFilter === 'Barcode' && this.type ==='Skip Scan'){
        tempFilter = 'skip'
        tempValue  = true;
      }

      if (this.toFilterModel) {
        filter['model'] = this.toFilterModel;
      }else{
        filter['model'] = '' ;
      }

      if (this.toFilterSerial) {
        filter['serial'] = this.toFilterSerial;
      }else{
        filter['serial'] = '' ;
      }

      if (this.typeSort) {
        filter['sort'] = this.typeSort;
      }else{
        filter['sort'] = '' ;
      }

      filter[tempFilter] = tempValue;
      
      return filter;
    },

    clearFilter() {
      this.selectedField = 'barcode';
      this.selectedFilter = 'Barcode'
      this.toFilter = '';
      this.toFilterModel = '';
      this.toFilterSerial = '';
      this.type = 'Set Up'
      this.typeDo = 'Set Up'

      this.getAllInventories({
        entry: this.perPage,
      }).then((data) => this.processMetadata(data.metadata));
    },
    edit(item,index,event){
      this.dataEdit={
        ...item,
        oldBarcode : item.barcode
      }
      this.editModal=true;
    },
    deleted(item,index,event){
      console.log("item", item);
      this.selectedItem = item
      this.modalDelete=true;
    },
    onSubmitEdit(event) {
      this.isLoading = true
      this.$store.dispatch("inventory/updateInventory",{id:this.dataEdit._id,payload:this.dataEdit}).
      then(x=>{
        this.getAllInventories({ 
        page: this.currentPage, 
        entry: this.perPage,
        serial: this.serial,
        barcode: this.barcode,
        sapDo: this.sapDo,
        model: this.model,
        //   warehouse: this.warehouse
      }).then((data) => this.processMetadata(data.metadata));

        this.editModal=false;
        this.$bvToast.toast("Edit Inventory run Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        this.isLoading = false
      })
      .catch((e) => {
        this.isLoading = false
        if (e.request.status === 400) {
            this.$bvToast.toast(e.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        })
    },
    confirmDelete(){
      console.log("hapus !!!");
      this.isLoading = true
      this.$store.dispatch("inventory/deleteInventory",{id:this.selectedItem._id}).
      then(x=>{
        this.getAllInventories({ 
        page: this.currentPage, 
        entry: this.perPage,
        serial: this.serial,
        barcode: this.barcode,
        sapDo: this.sapDo,
        model: this.model,
        //   warehouse: this.warehouse
      }).then((data) => this.processMetadata(data.metadata));

        this.modalDelete=false;
        this.$bvToast.toast("Delete Inventory run Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        this.isLoading = false
      })
      .catch((e) => {
            this.isLoading = false
            this.barcode = null;
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
    },
    cancelEdit() {
      this.editModal = false
    },
    onSortChange(){
      this.doFilter()
    },
    sortClick(sort){
      this.filters[sort]=!this.filters[sort]
      this.doFilter()
    },
  },
  computed: {
    ...mapGetters({
      items: 'inventory/filterInventory',
    }),
    modelVariant() {
      return this.filters.model ? 'success' : 'primary';
    },
    serialVariant() {
      return this.filters.serial ? 'success' : 'primary';
    },
    barcodeVariant() {
      return this.filters.barcode ? 'success' : 'primary';
    },

    permission() {
      return userAccess("View Inventory", "inventory_menu");
    },
  }
}
</script>

<style scoped>
@media (min-width: 872px) {
  .hide-on-desktop { display: none !important; }
}

@media (max-width: 871px) {
  .hide-on-mobile { display: none !important; }
}
.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
    margin: 0 0 0 5px;
  }

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin:  0 0 0 5px;
    width: 100px;
  }
}

.dropdown-show{
  overflow-y: auto;
  height: 100px;
}

.custom-datepicker .dropdown-menu {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  transform: none !important;
}
</style>